import { callApi, get } from '../utils/http-utils';

function uploadFiles(uploadData) {
    return callApi('file-upload', uploadData, { 'Content-Type': 'application/json' }, 'POST');
}

function getFiles(userName) {
    return get('get-uploaded-files', { 'Content-Type': 'application/json' }, {'userName': userName} );
}

export { uploadFiles, getFiles };