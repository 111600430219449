import './file-upload.scss'
import React from 'react';
import Files from 'react-files';
import { uploadFiles } from '../../service/file-upload-service';

const defaultState = {
    files: null,
    userName: '',
    infoText: '',
    isInfo: true
}

class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = defaultState;
        this.onFilesChange = this.onFilesChange.bind(this);
        this.onUploadFiles = this.onUploadFiles.bind(this);
    }

    async onFilesChange(files) {
        var b64Array = [];
        var f = files; // FileList object
        var b64Func = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ data: reader.result.split(',')[1], fileName: file.name });
            reader.onerror = error => reject(error);
        });
        // Read in the file as a data URL.
        if (f.length > 0) {
            for (let i = 0; i < f.length; i++) {
                let data = await b64Func(f[i]);
                // const fileData = { data, fileName: f[i].fileName };
                b64Array.push(data);
            }
        }
        const reqData = { files: b64Array, userName: this.props.userName }
        this.setState(reqData);
    }

    onFilesError(error, file) {
        console.log('error code ' + error.code + ': ' + error.message);
    }

    onUploadFiles() {
        if (this.state.files === null) {
            this.setState({...this.state, isInfo: false, infoText: "No file selected"});
        }

        if (this.state.files != null && this.state.files.length > 0 ){
            uploadFiles(this.state)
            .then(resp => {
                this.setState({files: null, userName: '', isInfo: true, infoText: resp.data});
            })
            .catch(err => {
                this.setState({files: null, userName: '', isInfo: false, infoText: 'Some error occured while uploading files'});
            });
        }
        
    }

    render() {
        return (
            <div className="files">
                <Files
                    className='files-dropzone'
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    accepts={['.pdf']}
                    multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                >
                    <button> Drop files here or click to select</button>
                </Files>
                <button className="uploadBtn" onClick={this.onUploadFiles}>Upload File(s)</button>
                <div id="infoDiv">
                    <span id="infoSpan" className={ this.state.isInfo ? "infoClass" : "errorClass"}>
                       {this.state.infoText}
                    </span>
                </div>
            </div>
        )
    }
}

export default FileUpload;