import Amplify, { Auth } from 'aws-amplify';
import { COGNITO_DOMAIN, COGNITO_REGION, COGNITO_SIGN_IN_URL, COGNITO_SIGN_OUT_URL, COGNITO_USERPOOL_ID, COGNITO_USERPOOL_WEB_CLIENT_ID } from './constants';

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'us-east-1:5cf54d99-cbdd-42b4-9a34-32293e80bc9a',

        // REQUIRED - Amazon Cognito Region
        region: COGNITO_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: COGNITO_USERPOOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: COGNITO_USERPOOL_WEB_CLIENT_ID,

        oauth: {
            domain: COGNITO_DOMAIN,//your_cognito_domain
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: COGNITO_SIGN_IN_URL,
            redirectSignOut: COGNITO_SIGN_OUT_URL,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});

// You can get the current config object
export const currentConfig = Auth.configure();