import './App.scss';
import react from 'react';
import { currentConfig } from './utils/amplify-config'
import Amplify, { Auth } from 'aws-amplify';
import FileUpload from './components/file-upload/file-upload';
import FileList from './components/file-list/file-list';
import Navbar from './components/navbar/navbar';

Amplify.configure(currentConfig);

class App extends react.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userSession: {},
      userName: ""
    }
  }

  componentDidMount() {
    console.log(Auth)
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log({ user })
        const userName = JSON.parse(user.attributes.identities)[0].userId;
        this.setState( { userName })
      })
      .catch(() => {
        console.log("Not signed in redirecting to sign in");
        Auth.federatedSignIn();
      });
    this.getSession();
  }

  getSession = async () => {
    await Auth.currentSession().then(userSession => {
      console.log(userSession)
      this.setState({ userSession });
    });
  }

  render() {
    if (this.state.userSession && Object.keys(this.state.userSession).length !== 0) {
      return (

        <div>
          <Navbar />
          <FileUpload userName={this.state.userName} />
          <FileList userName={this.state.userName} />
        </div>

      );
    } else {
      return (
        <>
          You will be automatically redirected or{" "}
          <a href="#" onClick={Auth.federatedSignIn}>
            click here to login
          </a>
          .
        </>
      );
    }
  }
}

export default App;
