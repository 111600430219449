import react from 'react';
import "./navbar.scss"
import { Auth } from 'aws-amplify';

class Navbar extends react.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            toggler: false
        }

        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        this.setState({ toggler: !this.state.toggler })
    }


    render() {

        return (
            <>
                <nav className="navbar shadow-sm sticky-top navbar-light navbar-expand-lg">
                    <div className="container-fluid">
                        <span className="navbar-brand ortho-logo" />
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation"
                            onClick={this.toggleNav}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={this.state.toggler ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end w-100">
                                {/* {this.state.menu.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <li className="nav-item" key={index}>
                                                <NavLink className="nav-link" exact activeClassName="selected" to={item.route}>{item.name}</NavLink>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li className="nav-item" key={index}>
                                                <NavLink className="nav-link" activeClassName="selected" to={item.route}>{item.name}</NavLink>
                                            </li>
                                        )
                                    }
                                })} */}
                            </ul>
                            <div>
                                <button type="button" className="btn btn-outline-danger" title="Logout" onClick={() => Auth.signOut()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"></path>
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>
                </nav>
            </>

        );
    }
}

export default Navbar;