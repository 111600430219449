import './file-list.scss'
import { useState } from "react";
import Table from "react-bootstrap/Table";
import { getFiles } from '../../service/file-upload-service';

function FileList ( {userName} ) {

    const [filesList, setFilesList] = useState([]);

    const loadFiles = () => {
        getFiles(userName)
        .then ((resp) => {
            setFilesList(resp.data);
        })
        .catch ((err) => {
            console.log(err);
        })
    }

    const getFileName = (filePath) => {
        return filePath.substring(filePath.lastIndexOf('/')+1);
    }

    return (
        <div  className="fileListComponent">
            <div>
                <button onClick={loadFiles}>Load list of uploaded files</button>
            </div>
            { filesList.length > 0 ? (
                <Table striped bordered hover responsive size="sm" className="filesTable">
                <thead>
                    <tr>
                        <th>File Name</th>
                    </tr>
                </thead>
                <tbody>
                    { filesList.map((value, index) => {
                        return (
                            <tr key={index}>
                                <td>{getFileName(value)}</td>
                            </tr>)
                    })}
                </tbody>
            </Table>
            ): (
                <span></span>
            )}
        </div>
    );
}

export default FileList;