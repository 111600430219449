import axios from 'axios';
import { Auth } from 'aws-amplify';
import * as Constants from './constants';

export async function get(endpoint, headers = { "content-type": "application/json" }, params = {}) {
    let accessToken;
    let jwt;
    let cognitoSession;
    await Auth.currentSession().then(res => {
        accessToken = res.getAccessToken();
        jwt = res.getIdToken().getJwtToken();

        cognitoSession = res;
    });
    if (cognitoSession.isValid()) {

        headers.authorization = "Bearer " + jwt;
        const url = Constants.CONFIG_API_URL + endpoint;
        return axios.get(url, { headers, params })

    } else {
        Auth.federatedSignIn();
        return "";
    }
}

/**
 * Makes a POST request to api gateway to upload files to s3 bucket.
 *
 * Returns: An XMLHttpRequest on success. Otherwise undefined (will redirect).
 */
 export async function callApi(endpoint, data, headers = null, method = "POST") {
    let accessToken;
    let jwt;
    let cognitoSession;
    await Auth.currentSession().then(res => {
      accessToken = res.getAccessToken();
      jwt = res.getIdToken().getJwtToken();
  
      cognitoSession = res;
      // isValid = res.isValid.bind(res);
      //console.log(accessToken, jwt, cognitoSession.isValid());
    });
    if (cognitoSession.isValid()) {
      const url = Constants.CONFIG_API_URL + endpoint;
      headers.authorization = "Bearer " + jwt;
      return axios.request({ method, data, url, headers });
    } else {
      Auth.federatedSignIn();
      return "";
    }
  }

// axios.interceptors.request.use(req => {
//     const jwt = Auth.user.signInUserSession.idToken.jwtToken;
//     req.headers.authorization = "Bearer " + jwt;
// })